<template>
  <div class="fab-container">
    <div class="fab-parent fab-icon-holder">
      <i class="fa fa-chevron-up"></i>
    </div>
    <ul class="fab-options">
      <li
        v-if="
          checkPrivilege(usersPrivilegeData.usersPrivilegeBrandServices.add)
        "
      >
        <span class="fab-label">
          <router-link :to="{ name: 'BrandServiceAdd' }" class="">
            {{ $t("brandServices.add") }}
          </router-link>
        </span>
        <div class="fab-icon-holder">
          <router-link :to="{ name: 'BrandServiceAdd' }" class="">
            <img src="@/assets/images/plus.svg" />
          </router-link>
        </div>
      </li>
      <li>
        <span class="fab-label" @click="open">
          {{ $t("filter") }}
        </span>
        <div class="fab-icon-holder" @click="open">
          <img src="@/assets/images/filter.svg" />
        </div>
      </li>
    </ul>

    <vue-bottom-sheet ref="CustomBottomSheet">
      <div class="row">
        <CustomInput
          :className="'col-md-12'"
          :id="'textSearch'"
          :value="filterData.textSearch"
          :title="$t('search')"
          :imgName="'search.svg'"
          v-on:changeValue="filterData.textSearch = $event"
        />
        <CustomSelectBoxWithImg
          :className="'col-md-12'"
          :id="'activityTypeToken'"
          :value="filterData.activityTypeToken"
          :options="activityTypeTokenOptions"
          v-on:changeValue="activityTypeChanged($event)"
          :title="$t('activityTypes.select')"
          :imgName="'activity-types.svg'"
        />
        <CustomSelectBoxWithImg
          :className="'col-md-12'"
          :id="'brandToken'"
          :value="filterData.brandToken"
          :options="brandTokenOptions"
          v-on:changeValue="filterData.brandToken = $event"
          :title="$t('brands.select')"
          :imgName="'brand.svg'"
        />
      </div>

      <div class="form-footer">
        <button
          name="submit"
          type="submit"
          class="btn btn-submit"
          @click.prevent="search"
        >
          {{ $t("search") }}
        </button>
        <button class="btn btn-cancel" @click.prevent="close">
          {{ $t("cancel") }}
        </button>
      </div>
    </vue-bottom-sheet>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { checkPrivilege } from "@/utils/functions";
import CustomInput from "@/components/general/CustomInput.vue";
import CustomSelectBoxWithImg from "@/components/general/CustomSelectBoxWithImg.vue";
import ActivityType from "@/models/activityTypes/ActivityType";
import Brand from "@/models/brands/Brand";
import BrandsFilter from "@/models/brands/BrandsFilter";

export default {
  props: {
    theFilterData: {
      type: Object,
    },
  },
  data() {
    return {
      language: localStorage.getItem("userLanguage") || "ar",
      filterData: this.theFilterData,
      activityType: new ActivityType(),
      activityTypeTokenOptions: [],
      brand: new Brand(),
      brandsFilter: new BrandsFilter(),
      brandTokenOptions: [],
    };
  },
  computed: {
    ...mapGetters([
      "userAuthorizeToken",
      "constantsListsData",
      "usersPrivilegeData",
    ]),
  },
  components: {
    CustomInput,
    CustomSelectBoxWithImg,
  },
  methods: {
    open() {
      this.$refs.CustomBottomSheet.open();
    },
    close() {
      this.$refs.CustomBottomSheet.close();
    },
    search() {
      this.close();
      this.$emit("search", this.filterData);
    },
    checkPrivilege,

    async getDialogOfActivityTypes() {
      this.isLoading = true;
      try {
        this.activityTypeTokenOptions =
          await this.activityType.getDialogOfActivityTypes(
            this.language,
            this.userAuthorizeToken
          );
      } catch (error) {
        this.showMsg(this.$t("errorCatch"));
      }
      this.isLoading = false;
    },
    activityTypeChanged(activityTypeToken) {
      this.filterData.activityTypeToken = activityTypeToken;
      this.filterData.brandToken = "";
      this.brandsFilter.activityTypeToken = activityTypeToken;
      this.getBrandDialog();
    },
    async getBrandDialog() {
      this.isLoading = true;
      try {
        this.brandTokenOptions = await this.brand.getBrandDialog(
          this.language,
          this.userAuthorizeToken,
          this.brandsFilter
        );
      } catch (error) {
        this.showMsg(this.$t("errorCatch"));
      }
      this.isLoading = false;
    },
  },
  async created() {
    this.getDialogOfActivityTypes();
    this.getBrandDialog();
  },
};
</script>
