import PaginationData from "@/models/general/PaginationData";
import BrandService from "./BrandService";
import BrandServicesFilter from "./BrandServicesFilter";

export default class BrandServices {
  constructor() {
    this.setInitialValue();
  }
  setInitialValue() {
    this.status = 0;
    this.msg = "";
    this.pagination = new PaginationData();
    this.brandServicesData = [];
    this.brandService = new BrandService();
    this.filterData = new BrandServicesFilter();
  }
  fillData(data) {
    this.status = data.status;
    this.msg = data.msg;
    this.pagination.fillData(data.pagination);
    this.brandServicesData = data.brandServicesData;
  }
}
