<template>
  <div class="">
    <PreLoader v-if="isLoading" />
    <ExceptionWithImg v-if="exceptionMsg" :msg="exceptionMsg" />

    <div v-if="brandServices.brandServicesData">
      <div class="row">
        <BrandServiceCard
          v-for="(brandService, index) in brandServices.brandServicesData"
          :key="index"
          :defaultImg="brandServices.brandService.defaultImg"
          v-on:setBrandServiceData="brandServices.brandService.fillData($event)"
          :brandService="brandService"
        />
      </div>

      <ActionsData :actionsData="brandServices.brandService" />
      <BrandServiceInfo :brandService="brandServices.brandService" />
      <BrandServiceDelete
        :brandService="brandServices.brandService"
        v-on:refresh="getAllBrandServices()"
      />

      <Pagination
        v-if="!isLoading"
        :paginationData="brandServices.pagination"
        v-on:changePage="changePage"
      />
    </div>

    <BrandServiceFloatBtns
      :theFilterData="brandServices.filterData"
      v-on:search="search($event)"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import createToastMixin from "@/utils/create-toast-mixin";
import PreLoader from "@/components/general/PreLoader.vue";
import ExceptionWithImg from "@/components/general/ExceptionWithImg.vue";
import { STATUS } from "@/utils/constants";
import ActionsData from "@/components/general/ActionsData.vue";
import BrandServiceCard from "@/components/brandServices/BrandServiceCard.vue";
import BrandServiceInfo from "@/components/brandServices/BrandServiceInfo.vue";
import BrandServiceDelete from "@/components/brandServices/BrandServiceDelete.vue";
import BrandServiceFloatBtns from "@/components/brandServices/BrandServiceFloatBtns.vue";
import Pagination from "@/components/general/Pagination.vue";
import BrandServices from "@/models/brandServices/BrandServices";

export default {
  mixins: [createToastMixin],
  components: {
    PreLoader,
    ExceptionWithImg,
    ActionsData,
    BrandServiceCard,
    BrandServiceInfo,
    BrandServiceDelete,
    BrandServiceFloatBtns,
    Pagination,
  },
  computed: {
    ...mapGetters(["userAuthorizeToken"]),
  },
  props: ["activityTypeToken", "brandToken"],
  data() {
    return {
      isLoading: false,
      exceptionMsg: null,
      exceptionImg: null,
      language: localStorage.getItem("userLanguage") || "ar",
      brandServices: new BrandServices(),
    };
  },
  methods: {
    changePage(page) {
      this.brandServices.pagination.selfPage = page;
      this.getAllBrandServices();
    },
    search(data) {
      this.brandServices.filterData.fillData(data);
      this.brandServices.brandServicesData = [];
      this.getAllBrandServices();
    },
    async getAllBrandServices() {
      this.isLoading = true;
      try {
        const response =
          await this.brandServices.brandService.getAllBrandServices(
            this.language,
            this.userAuthorizeToken,
            this.brandServices.pagination,
            this.brandServices.filterData
          );
        if (response.data.status == STATUS.SUCCESS) {
          this.exceptionMsg = null;
          this.brandServices.fillData(response.data);
        } else if (response.data.status == STATUS.NO_CONTENT) {
          this.brandServices.brandServicesData = null;
          this.exceptionMsg = response.data.msg;
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.brandServices.brandServicesData = null;
          this.$store.dispatch("logoutUser");
        } else {
          this.brandServices.brandServicesData = null;
          this.exceptionMsg = response.data.msg;
        }
      } catch (error) {
        this.brandServices.brandServicesData = null;
        this.exceptionMsg = this.$t("errorCatch");
      }
      this.isLoading = false;
    },
    setFilter() {
      this.brandServices.filterData.activityTypeToken = this.activityTypeToken;
      this.brandServices.filterData.brandToken = this.brandToken;
    },
  },
  async created() {
    this.setFilter();
    this.getAllBrandServices();
  },
};
</script>
